import { Constants } from "../../../Helpers/Constants";
import type { Product } from "../../../Models/ShoptetProduct";
import { TariffType } from "../../../Models/ShoptetTariffType";
import { defaultErrorHandler } from "../../sharedFunctions";

/**
 * Interface represents tariff fetched via find-tariff
 */
interface FindTariffTariff {
  durationYears: number;
  premium: number;
  productCode: string;
  txtProductName: string;
}

/**
 * Interface represents tariff types fetched via find-tariff
 */
interface FindTariffTariffType {
  incompatibleTariffTypes: string[];
  tariffType: string;
  tariffs: FindTariffTariff[];
  txtCartPopupHTML: string;
  txtCartTitle: string;
  txtShortDescription: string;
  txtTariffTypeName: string;
}

/**
 * Interface represent "the package" of fetched tariff linked with the product by GUID.
 */
interface FindTariffFoundTariff {
  currency: string;
  guid: string;
  tariffTypes: FindTariffTariffType[];
  value: number;
}

/**
 * Function serializes product for find-tariff AJAX request
 * @param product is the product that is being serialized
 * @returns serialized product with only necessary attributes
 */
function serializeFindTariffProduct(product: Product) {
  const { guid, productCode, value, currency } = product;

  return {
    guid,
    productCode,
    value,
    currency,
  };
}

/**
 * Function fills up products with found tariffs via find-tariff.
 * @param products are products that are going to be filled up with found tariffs
 * @param foundTariffs are found tariffs via find-tariff
 * @returns products with tariffs
 */
function fillProductsWithTariffs<T extends Product>(products: T[], foundTariffs: FindTariffFoundTariff[]): T[] {
  return products.map((p, index) => {
    // indexing foundTariffs is possible (even necessary for product variants) since products and foundTariffs
    // have the same length and are ordered in the same way (by API definition)
    const { tariffTypes: foundTariffTypes } = foundTariffs[index];

    return {
      ...p,
      tariffTypes: foundTariffTypes.map(
        ftt =>
          new TariffType(
            ftt.tariffs,
            ftt.tariffType,
            ftt.txtTariffTypeName,
            ftt.txtCartTitle,
            ftt.txtCartPopupHTML,
            ftt.txtShortDescription,
            ftt.incompatibleTariffTypes
          )
      ),
    };
  });
}

/**
 * Function creates body for find tariff AJAX request
 * @param products are products that need to be serialized for find tariff AJAX request
 * @returns body with shopId and serialized products
 */
function createFindTariffRequestBody(products: Product[]) {
  return {
    shopId: parseInt(shopId),
    products: products.map(serializeFindTariffProduct),
  };
}

/**
 * Function fetches tariffs for geiven products via AJAX request
 * @param products are the products that need fetched tariffs
 * @returns products with fetched tariffs
 */
export async function findTariff<T extends Product>(products: T[]): Promise<T[]> {
  if (!products.length) {
    return [];
  }

  const body = createFindTariffRequestBody(products);

  let foundTariffs: FindTariffFoundTariff[] = [];
  try {
    foundTariffs = (await $.ajax({
      type: "POST",
      url: Constants.INSURANCES_URL,
      contentType: "application/json",
      accepts: "application/json",
      data: JSON.stringify(body),
    })) as FindTariffFoundTariff[];

    if (foundTariffs.length !== products.length) {
      throw new Error("The count of tariffs fetched from tuito servers does not match the count of products!");
    }
  } catch (err) {
    defaultErrorHandler(err);
  }

  return fillProductsWithTariffs(products, foundTariffs);
}
