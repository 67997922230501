export class Tariff {
  durationYears: number;
  productCode: string;
  premium: number;
  txtProductName: string;
  itemId?: string;

  constructor(years: number, pCode: string, premium: number, pName: string) {
    this.durationYears = years;
    this.productCode = pCode;
    this.premium = premium;
    this.txtProductName = pName;
  }
}
