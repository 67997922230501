type StoredCartAction = () => void;

/**
 * Class stores cart actions and executes them
 */
export default abstract class CartActionsDispatcher {
  private static actions: StoredCartAction[] = [];

  /**
   * Method executes 1 stored cart action.
   * If there is no stored actions then does nothing
   */
  static dispatchNextAction() {
    const action = CartActionsDispatcher.actions.pop();
    if (!action) {
      return;
    }

    action();
  }

  /**
   * Method checks if there are any actions stored
   * @returns true if there are no actions stored otherwise false
   */
  static isEmpty() {
    return CartActionsDispatcher.actions.length === 0;
  }

  /**
   * Method stores given action.
   * @param action is action that is going to be stored
   */
  static enqueueAction(action: () => void) {
    CartActionsDispatcher.actions.push(action);
  }
}