import type { Whitelist } from "./whitelist";
import { AffectedProductsFlag } from "./whitelist";
import whitelist from "./whitelist";

/**
 * Class manages logic for whitelist.
 * Contains the whitelist, returns the whitelist, checks if specific product from specific shop is on whitelist.
 */
export default abstract class WhitelistManager {
  /**
   * The whitelist.
   * Shops on the whitelist can have all products there or just specific ones.
   * The whitelist is array - the first element is shopid, the second element is flag signalizing that all products of the shop is on whitelist OR the second element is array of product guids that are on the whitelist.
   */
  private static readonly _whitelist: Whitelist = whitelist;

  private static getWhitelistProductByShopId(shopId: string) {
    return WhitelistManager._whitelist.find(([whitelistShopId, _]) => whitelistShopId === shopId);
  }

  /**
   * Method checks if specific shop with specific product is on whitelist.
   * Method takes ALL_PRODUCTS_FLAG into considiration.
   * @param shopId is id of the shop from which the product is
   * @param productGuid is guid of the product we need to know if is on the whitelist
   * @returns true if the shop is on whitelist with ALL_PRODUCTS_FLAG or if the shop is on whitelist with the guid of the product otherwise returns false
   */
  public static isProductOnWhitelist(shopId: string, productGuid: string): boolean {
    // finds shop on the whitelist
    const shopOnWhitelist = WhitelistManager.getWhitelistProductByShopId(shopId);

    if (shopOnWhitelist) {
      // destructure only "the options" (ALL_PRODUCTS_FLAG or product guids)
      const [, options] = shopOnWhitelist;

      // if options are ALL_PRODUCTS_FLAG then the product is considered as is on the whitelist
      if (options === AffectedProductsFlag.ALL) {
        return true;
      }

      // if options are array (typecheck) and also includes the product's guid then the product is on whitelist
      if (Array.isArray(options) && options.includes(productGuid)) {
        return true;
      }
    }

    // else the shop or the product is not on the whitelist
    return false;
  }

  public static getProductDetailPosition(shopId: string) {
    const shopOnWhitelist = WhitelistManager.getWhitelistProductByShopId(shopId);

    const [, , position] = shopOnWhitelist ?? [];
    return position;
  }
}
