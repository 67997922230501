import type { Product } from "../../../Models/ShoptetProduct";
import type { TariffType } from "../../../Models/ShoptetTariffType";

/**
 * Function decides if given product code is product code of tariff or not
 * @param tariffProductCode is product code of the product that might be tariff
 * @returns true if the product code belongs to the tariff otherwise false
 */
export function isProductTuitoTariffByProductCode(tariffProductCode: string): boolean {
  const TARIFF_PRODUCT_CODE_PREFIX = "TUITO-";

  return tariffProductCode.startsWith(TARIFF_PRODUCT_CODE_PREFIX);
}

/**
 * Function gets tariff type containing given tariff (by tariff's product code)
 * @param products are products in which tariff type will be searched
 * @param tariffProductCode is product code of given tariff
 * @returns tariff type if tariff type is found otherwise undefined
 */
export function getTariffTypeByTariffProductCode<T extends Product>(products: readonly T[], tariffProductCode: string): TariffType | undefined {
  for (const product of products) {
    const { tariffTypes } = product;

    const tt = tariffTypes.find(type => type.tariffs.some(t => t.productCode === tariffProductCode));
    if (tt) {
      return tt;
    }
  }
}

/**
 * Function gets tariff type by given typename
 * @param products are products in which tariff types will be searched
 * @param type is tariff type typename
 * @returns tariff type if tariff type is found otherwise undefined
 */
export function getTariffTypeByType<T extends Product>(products: readonly T[], type: string): TariffType | undefined {
  for (const product of products) {
    const { tariffTypes } = product;

    const tt = tariffTypes.find(tt => tt.type === type);
    if (tt) {
      return tt;
    }
  }
}

/**
 * Function returns all products that have given tariff.
 * @param products are all possible products which will be filtered by given tariff
 * @param tariffProductCode is product code of given tariff
 * @returns array of all products that have given tariff
 */
export function getProductsByTariffProductCode<T extends Product>(products: readonly T[], tariffProductCode: string): T[] {
  return products.reduce<T[]>((acc, nextProduct) => {
    const tt = getTariffTypeByTariffProductCode([nextProduct], tariffProductCode);
    if (tt) {
      return [...acc, nextProduct];
    }

    return acc;
  }, []);
}

/**
 * Function finds all tariffs in given produdct are returns their product codes
 * @param products are all products which will be filtered to tariffs only and mapped to product codes only.
 * @returns array of tariff product codes
 */
export function getTariffProductCodes<T extends Product>(products: readonly T[]): string[] {
  return products.map(p => p.productCode).filter(c => isProductTuitoTariffByProductCode(c));
}

/**
 * Function calculates total price for tariff for specific product
 * @param tariffType is tariff type in which the tariff is
 * @param parentProductCount is count of the specific product for which the total price is calculated
 * @param tariffProductCodesInCart is array of product codes of all tariffs in the cart
 * @returns total price of tariff for specific product
 */
export function calculateTariffTypeTotalPrice(
  tariffType: TariffType,
  parentProductCount: number,
  tariffProductCodesInCart: string[]
): number {
  const { tariffs } = tariffType;

  const tariffInCart = tariffs.find(t => tariffProductCodesInCart.includes(t.productCode));
  if (!tariffInCart) {
    return 0;
  }

  return tariffInCart.premium * parentProductCount;
}

export function addProductIntoArrayIfNotPresent<T extends Product>(product: T, arr: readonly T[]): T[] {
  if (arr.find(cp => cp.productCode === product.productCode)) {
    return [...arr];
  }
  
  return [...arr, product];
}