export enum AffectedProductsFlag {
  ALL,
}

export enum ProductDetailPosition {
  PRODUCT_DETAIL = "PRODUCT_DETAIL",
  ADVANCED_ORDER = "ADVANCED_ORDER",
  ALL = "ALL",
}

// shops that should be on whitelist only in DEV mode (website has DEV cookie set to '1')
// const tmpShop: [[string, string | string[]]] =
//   getCookieValue("DEV") === "1" ? [["251042", AffectedProductsFlag.ALL]] : [["999999999", AffectedProductsFlag.ALL]];

/**
 * The type of the whitelist itself.
 * The first element is ID of the shop that is on the whitelist.
 * The second element could be:
 *      - ALL_PRODUCTS_FLAG signalizing that all products from the shop are on whitelist
 *      - array of guids of the products on the whitelist
 */
// @todo in the case of having more options rather rework this array structure to array of objects
export type Whitelist = [string, AffectedProductsFlag | string[], ProductDetailPosition?][];

/**
 * whitelist is tuple of shops with enabled insurance on product detail page.
 */
const whitelist: Whitelist = [
  //SHOPNAME
  //["SHOPID", "PRODID"]

  ["461998", AffectedProductsFlag.ALL],
  //tablety.sk
  ["33332", AffectedProductsFlag.ALL],
  //elektrolinhart //ALL //https://www.elektrolinhart.cz/samsung-galaxy-m12-64-gb-cerny/
  ["449237", AffectedProductsFlag.ALL],
  //istage //https://www.istage.cz/apple-iphone-12-mini-64gb/
  ["64929", AffectedProductsFlag.ALL],
  // jabkolevne.cz
  ["359599", AffectedProductsFlag.ALL],
  // tvojfon.sk
  ["433368", AffectedProductsFlag.ALL],
  // obchod.itcomplet.sk
  ["353560", AffectedProductsFlag.ALL],
  // elu.sk
  ["323453", AffectedProductsFlag.ALL],
  // tekra.sk
  ["436419", AffectedProductsFlag.ALL],
  // pocitace1024.cz
  ["124804", AffectedProductsFlag.ALL],
  //https://www.bestmarket.sk/
  ["457192", AffectedProductsFlag.ALL],
  //https://www.ipato.cz/
  ["94161", AffectedProductsFlag.ALL],
  //https://www.spotrebice-centrum.sk/
  ["312749", AffectedProductsFlag.ALL],
  //https://www.spotrebice-centrum.cz/
  ["292080", AffectedProductsFlag.ALL],
  // onlinegastro.cz
  ["181078", AffectedProductsFlag.ALL],
  // tvdigitalne.cz
  ["350077", AffectedProductsFlag.ALL],
  // xiaomimarket.cz
  ["173295", AffectedProductsFlag.ALL],
  // sapabike.cz
  ["473364", AffectedProductsFlag.ALL],

  // tenolix.cz
  ["212053", AffectedProductsFlag.ALL],
  // www.kolamorava.cz
  ["394739", AffectedProductsFlag.ALL],
  // innocentstore.sk
  ["440411", AffectedProductsFlag.ALL],
  // innocentstore.cz
  ["462577", AffectedProductsFlag.ALL],
  // ecophone.sk
  ["365236", AffectedProductsFlag.ALL],
  // cyklonovak.cz
  ["256671", AffectedProductsFlag.ALL],
  // fitness-zone.cz
  ["58421", AffectedProductsFlag.ALL],
  // parolek-shop.cz
  ["481776", AffectedProductsFlag.ALL, ProductDetailPosition.PRODUCT_DETAIL],

  // www.drezy-baterie.sk/
  ["467846", AffectedProductsFlag.ALL],
  // https://www.vrplus.cz/
  ["543854", AffectedProductsFlag.ALL],
  // www.tenolix.cz
  ["212053", AffectedProductsFlag.ALL],

  // https://www.kolor.cz/
  ["493957", AffectedProductsFlag.ALL],
  // https://www.cmpsport.cz/
  ["251042", AffectedProductsFlag.ALL],
  // https://www.spikelektro.sk/
  ["488837", AffectedProductsFlag.ALL],
  // https://www.shopmelmar.eu/
  ["114953", AffectedProductsFlag.ALL],
  // iphonelab.cz
  ["123479", AffectedProductsFlag.ALL],

  //https://www.drezy-baterie.sk/elica-ciak-lux-gr-a-l-56/
  ["467846", ["32b86940-731e-11ec-8349-bc97e1a74491"]],
  //iphonarna.cz
  //["302371", AffectedProductsFlag.ALL],
  //https://www.iphonarna.cz/apple-iphone-se--2020--64gb-cerny/
  ["302371", ["e8db7afa-ab79-11ea-9f75-ecf4bbd79d2f"]],
];

export default whitelist;
