import type { ProductInCart } from "../../../Models/ShoptetProduct";
import type { Tariff } from "../../../Models/ShoptetTariff";
import type { TariffType } from "../../../Models/ShoptetTariffType";
import { debounce } from "../../../utils/functionUtils";
import Cart from "../products/Cart";
import CartActionsDispatcher from "../cartActions/CartActionsDispatcher";
import type ShopConfiguration from "../ShopConfiguration";
import {
  getProductsByTariffProductCode,
  getTariffProductCodes,
  getTariffTypeByTariffProductCode,
  getTariffTypeByType,
  isProductTuitoTariffByProductCode,
} from "../utils/utils";
import CheckoutViewer from "./CheckoutViewer";

const HANDLE_ON_CLICK_DEBOUNCE_TIMEOUT = 500;

export default class CheckoutController {
  private checkoutViewer: CheckoutViewer;

  /**
   * Product codes of all tariffs in the cart (stored so the function for getting them doesn't have to be called over and over again)
   */
  private tariffCart: string[] = [];

  /**
   * Product codes of all tariffs that should be in the cart.
   */
  private finalTariffCart: string[] = [];

  constructor(private readonly cart: Cart, private readonly shopConfiguration: ShopConfiguration) {
    this.checkoutViewer = new CheckoutViewer(this.shopConfiguration, (...args) => this.onTariffChecked(...args));
    this.tariffCart = getTariffProductCodes(cart.products);
    this.finalTariffCart = this.tariffCart.slice();
  }

  /**
   * Method makes update of controller to current state
   * @todo - preferably updating _cartTariffProductCodes move here from whole controller once shoptet fix it's bugs
   */
  update() {
    this.tariffCart = getTariffProductCodes(this.cart.products);

    this.checkoutViewer.hideTariffRowByProductCodes(this.tariffCart);

    this.checkoutViewer.buildAndInsertTables(this.cart.products, this.finalTariffCart);

    if (!CartActionsDispatcher.isEmpty() || this.cart.isCartBeingUpdated) {
      this.checkoutViewer.lockTariffTables();
    }
  }

  private onTariffChecked(isChecked: boolean, clickedTariff: Tariff) {
    if (isChecked) {
      this.addTariffToFinalCart(clickedTariff);
    } else {
      this.removeTariffFromFinalCart(clickedTariff);
    }

    this.checkoutViewer.mimicTariffChanges(this.finalTariffCart);

    this.saveFinalCartDebounced();
  }

  private readonly saveFinalCartDebounced = debounce(() => {
    this.enqueueCartUpdateActions();

    if (CartActionsDispatcher.isEmpty()) {
      return;
    }

    this.checkoutViewer.lockTariffTables();

    CartActionsDispatcher.dispatchNextAction();
  }, HANDLE_ON_CLICK_DEBOUNCE_TIMEOUT);

  private addTariffToFinalCart(tariff: Tariff) {
    const productsWithTariff = getProductsByTariffProductCode(this.cart.products, tariff.productCode);
    if (!productsWithTariff.length) {
      throw new Error("No products have been found by given tariff while trying to add tariff to the cart!");
    }

    const tariffType = getTariffTypeByTariffProductCode(productsWithTariff, tariff.productCode);
    if (!tariffType) {
      throw new Error("Tariff type of checked checkbox has not been found!");
    }

    this.removeTariffTypeFromFinalCart(tariffType);
    this.removeIncompatibleTariffTypesFromFinalCart(productsWithTariff[0], tariffType);
    this.finalTariffCart.push(tariff.productCode);
  }

  private removeTariffFromFinalCart(clickedTariff: Tariff) {
    const indexOfTariffInCart = this.finalTariffCart.indexOf(clickedTariff.productCode);
    if (indexOfTariffInCart === -1) {
      return;
    }

    this.finalTariffCart.splice(indexOfTariffInCart, 1);
  }

  private removeTariffTypeFromFinalCart(clickedTariffType: TariffType) {
    const { tariffs } = clickedTariffType;
    tariffs.forEach(t => this.removeTariffFromFinalCart(t));
  }

  private removeIncompatibleTariffTypesFromFinalCart(productWithTariff: ProductInCart, clickedTariffType: TariffType) {
    const { incompatibleTariffs: incompatibleTariffTypeNames } = clickedTariffType;

    if (!incompatibleTariffTypeNames.length) {
      return;
    }

    const incompatibleTariffTypes = incompatibleTariffTypeNames
      .map(ittn => getTariffTypeByType([productWithTariff], ittn))
      .filter((x): x is TariffType => !!x);

    incompatibleTariffTypes.forEach(itt => this.removeTariffTypeFromFinalCart(itt));
  }

  private enqueueCartUpdateActions(): void {
    const products = [...this.cart.products];

    this.finalTariffCart.forEach(pc => {
      const index = products.findIndex(tp => tp.productCode === pc);

      const shouldAddToCart = index === -1;
      if (shouldAddToCart) {
        const productsWithTariff = getProductsByTariffProductCode(this.cart.products, pc);
        const amount = Cart.getRightAmoutTariffInCart(productsWithTariff);

        // manipulating with advnaced order config is workaround for
        // buggy silent parameter in addToCart function
        // @todo - once silent parameter is fully working, remove showAvancedOrder
        // manipulation (the setTimeout shoptet used by itself as workaround)
        CartActionsDispatcher.enqueueAction(() => {
          shoptet.config.showAdvancedOrder = undefined;
          shoptet.cartShared.addToCart({ productCode: pc, amount });
          this.reverseShowAdvancedOrderChange();
        });
      } else {
        products.splice(index, 1);
      }
    });

    // here products contains only non-tariff products and tariffs that have been removed by user
    products
      .filter(tp => isProductTuitoTariffByProductCode(tp.productCode))
      .forEach(cp => CartActionsDispatcher.enqueueAction(() => shoptet.cartShared.removeFromCart({ itemId: cp.cartItemId })));
  }

  // @todo - remove this function once silent parameter is fixed
  private reverseShowAdvancedOrderChange = debounce(() => {
    shoptet.config.showAdvancedOrder = this.shopConfiguration.originalAdvancedOrder;
  }, 2000);

  getItemsInCartCount(): number {
    return this.checkoutViewer.getItemsInCartCount();
  }
}
