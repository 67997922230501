import { LayoutVariant } from "../../../Helpers/shoptet";
import { Constants } from "../../../Helpers/Constants";
import type { ProductInCart } from "../../../Models/ShoptetProduct";
import type { Tariff } from "../../../Models/ShoptetTariff";
import type { TariffType } from "../../../Models/ShoptetTariffType";
import { getCurrencySymbol, getLocalizedLengthString } from "../../sharedFunctions";
import { calculateTariffTypeTotalPrice } from "../utils/utils";
import type ShopConfiguration from "../ShopConfiguration";

const TARIFF_CELL_SELECTOR = "div#tariff-cell";
const INFO_CIRCLE_SPAN_SELECTOR = "span[name='infoCircle']";

export default class CheckoutViewer {
  constructor(
    private readonly shopConfiguration: ShopConfiguration,
    private onCheckboxClickHandler: (isChecked: boolean, clickedTariff: Tariff) => void
  ) {
    this.setTuitoModalCloseEvent();
  }

  /**
   * Method builds and inserts tables from all given products
   * @param products are all products in the cart
   * @param tariffProductCodesInCart are tariff product codes of tariffs in the cart
   */
  buildAndInsertTables(products: readonly ProductInCart[], tariffProductCodesInCart: string[]): void {
    this.removeExistingTariffTables();

    products.forEach(product => {
      const { productCode, guid, tariffTypes, currency, count } = product;

      const productRow = this.findProductRowByProductCode(productCode);
      if (!productRow.length) {
        console.log(`Product with given productCode: ${productCode} has not been found in DOM`);
        return;
      }

      const tariffTypeRows = tariffTypes.map(tt =>
        this.createRowForTariffType(guid, productCode, count, tt, currency, tariffProductCodesInCart)
      );

      tariffTypeRows.forEach(ttr => productRow.after($(ttr)));
    });
  }

  /**
   * Method crates row for tariff type
   * @param productGuid is GUID of product that has the tariff
   * @param productProductCode is product code of the product that has the tariff
   * @param productCount is count of the product (useful to display right total price)
   * @param tariffType is tariff type for which the row is going to be build
   * @param currencyIsoCode is currency in iso code
   * @param tariffProductCodesInCart is array of tariff product codes in the cart
   * @returns tariff type row in JQuery format
   */
  private createRowForTariffType(
    productGuid: string,
    productProductCode: string,
    productCount: number,
    tariffType: TariffType,
    currencyIsoCode: string,
    tariffProductCodesInCart: string[]
  ): JQuery {
    const newRow = this.createEmptyTariffTypeRow(productProductCode);

    const logoAndDescriptionCells = this.createLogoAndDecriptionCell(tariffType);

    const tariffCell = this.shopConfiguration.simplifiedCartTableRow
      ? this.createEmptyTariffCellSimplified()
      : this.createEmptyTariffCell();
    const unitPriceCell = this.createEmptyUnitPriceCell();
    const totalpriceCell = this.createEmptyTotalPriceCell();

    const currencyString = getCurrencySymbol(currencyIsoCode);

    const { tariffs } = tariffType;
    tariffs.forEach((t, index) => {
      // @todo - find reason for this condition
      if (Object.keys(t).length === 0 || !t.productCode) {
        return;
      }

      const lengthString = getLocalizedLengthString(t.durationYears);
      const variantString = t.premium.toLocaleString("cs-CZ");

      let bodyTariffCell: JQuery;
      if (this.shopConfiguration.simplifiedCartTableRow) {
        bodyTariffCell = this.createBodyTariffCellSimplified(
          productGuid,
          productProductCode,
          t,
          lengthString,
          tariffProductCodesInCart,
          variantString,
          currencyString
        );

        $(TARIFF_CELL_SELECTOR, tariffCell).append(bodyTariffCell);
      } else {
        bodyTariffCell = this.createBodyTariffCell(productGuid, productProductCode, t, lengthString, tariffProductCodesInCart);

        $(TARIFF_CELL_SELECTOR, tariffCell).append(bodyTariffCell);

        const bodyUnitPriceCell = this.createBodyUnitPriceCell(variantString, currencyString);
        $("div", unitPriceCell).append(bodyUnitPriceCell);
      }
    });

    const totalTariffTypePrice = calculateTariffTypeTotalPrice(tariffType, productCount, tariffProductCodesInCart);
    if (totalTariffTypePrice) {
      const bodyTotalPriceCell = this.createBodyTotalPriceCell(totalTariffTypePrice.toLocaleString("cs-CZ"), currencyString);
      totalpriceCell.append(bodyTotalPriceCell);
    }

    newRow.append(logoAndDescriptionCells).append(tariffCell).append(unitPriceCell).append(totalpriceCell);
    return newRow;
  }

  private createLogoAndDecriptionCell(tariffType: TariffType): JQuery {
    if (this.shopConfiguration.simplifiedCartTableRow) {
      return this.createLogoAndDecriptionCellOneColumnSimplified(tariffType.cartTitle, tariffType.description, tariffType.popup);
    } else if (this.shopConfiguration.hasFourCheckoutCartColumns) {
      return this.createLogoAndDecriptionCellOneColumn(
        tariffType.cartTitle,
        tariffType.typeName,
        tariffType.description,
        tariffType.popup
      );
    } else {
      return this.createLogoAndDescriptionCellsTwoColumn(
        tariffType.cartTitle,
        tariffType.typeName,
        tariffType.description,
        tariffType.popup
      );
    }
  }

  /**
   * Method creats total price cell body
   * @param price is total price of tariffs for specific product
   * @param currency is currency in which the price is
   * @returns total price cell body in jQuery format
   */
  private createBodyTotalPriceCell(price: string, currency: string): JQuery {
    return $(`
      <strong class='price-final'>
        ${price} ${currency}
      </strong>
    `);
  }

  /**
   * Method creates body for tariff cell
   * @param productGuid is GUID of product that has the tariff
   * @param productProductCode is product code of the product that has the tariff
   * @param tariff is tariff for which body is build
   * @param lengthString is duration of the insurance
   * @param tariffProductCodesInCart is array of tariff product codes in the cart
   * @returns body for tariff cell in JQuery format
   */
  private createBodyTariffCell(
    productGuid: string,
    productProductCode: string,
    tariff: Tariff,
    lengthString: string,
    tariffProductCodesInCart: string[]
  ): JQuery {
    const bodyTariffCell = $(`
      <div>
        <input type='checkbox' id='${productProductCode}_${tariff.productCode}' productId='${productGuid}' tariffId='${tariff.productCode}' data-owner='tuito' />
        <label for='${productProductCode}_${tariff.productCode}'>
            <b>${lengthString}</b>
        </label>
        <br />
      </div>
    `);

    $("input", bodyTariffCell).click(() => this.onCheckboxClickHandler($("input", bodyTariffCell).prop("checked"), tariff));
    $("input", bodyTariffCell).prop("checked", tariffProductCodesInCart.includes(tariff.productCode));

    return bodyTariffCell;
  }

  private createBodyTariffCellSimplified(
    productGuid: string,
    productProductCode: string,
    tariff: Tariff,
    lengthString: string,
    tariffProductCodesInCart: string[],
    variantString: string,
    currencyString: string
  ): JQuery {
    const bodyTariffCell = $(`
      <div class='tariff-cell-simplified'>
        <input type='checkbox' id='${productProductCode}_${tariff.productCode}' productId='${productGuid}' tariffId='${tariff.productCode}' data-owner='tuito' />
        <label for='${productProductCode}_${tariff.productCode}'>
            ${lengthString} za <b> ${variantString} ${currencyString} </b>
        </label>
      </div>
    `);

    $("input", bodyTariffCell).click(() => this.onCheckboxClickHandler($("input", bodyTariffCell).prop("checked"), tariff));
    $("input", bodyTariffCell).prop("checked", tariffProductCodesInCart.includes(tariff.productCode));

    return bodyTariffCell;
  }

  /**
   * Method creates body for unit price cell.
   * @param variantString represents price
   * @param currencyString represents currency
   * @returns body for unit price cell in JQuery format
   */
  private createBodyUnitPriceCell(variantString: string, currencyString: string): JQuery {
    return $(`
      <strong class='tuito-price-unit'>
        ${variantString} ${currencyString}
      </strong>
      <span class='unit-value'>/ks</span>
      <br/>
    `);
  }

  private createLogoAndDecriptionCellOneColumn(
    rowTitle: string,
    infoName: string,
    description: string,
    popupText: string
  ): JQuery {
    const logoAndDescriptionCell = $(`
      <td colspan=2 class='p-name'>
        <div class='single-merchandise-foto large-2 medium-3 small-0 columns left'>
          ${Constants.tuitoLogo}
        </div>
        ${rowTitle} &nbsp;
        <span name='infoCircle' title='${infoName}'>${Constants.infoCircle}</span>
        <br />
        <span class='p-description'>${description}</span>
      </td>
    `);

    $(`${INFO_CIRCLE_SPAN_SELECTOR}`, logoAndDescriptionCell).click(() => this.showTuitoPopupModal(popupText));

    return logoAndDescriptionCell;
  }

  private createLogoAndDecriptionCellOneColumnSimplified(rowTitle: string, description: string, popupText: string): JQuery {
    const logoAndDescriptionCell = $(`
      <td class='colspan=2 td-simplified'>
        <div class='single-merchandise-foto large-2 medium-3 small-0 columns left'>
          ${Constants.tuitoLogo}
        </div>
        <div class='title'>
          <div class='text-and-info'>
            <span><p>${rowTitle}</p></span>
            &nbsp;
            <span name='infoCircle' title='${description}'>${Constants.infoCircle}</span>
          </div>  
        </div>
      </td>
    `);

    $(`${INFO_CIRCLE_SPAN_SELECTOR}`, logoAndDescriptionCell).click(() => this.showTuitoPopupModal(popupText));

    return logoAndDescriptionCell;
  }

  private createLogoAndDescriptionCellsTwoColumn(
    rowTitle: string,
    infoName: string,
    description: string,
    popupText: string
  ): JQuery {
    const logoAndDescriptionCells = $(`
      <td class='cart-p-image'>${Constants.tuitoLogo}</td>
      <td colspan=2 class='p-name'>
        ${rowTitle} &nbsp;
        <span name='infoCircle' title='${infoName}'>${Constants.infoCircle}</span>
        <br />
        <span class='p-description'>${description}</span>
      </td>
    `);

    $(`${INFO_CIRCLE_SPAN_SELECTOR}`, logoAndDescriptionCells).click(() => this.showTuitoPopupModal(popupText));

    return logoAndDescriptionCells;
  }

  /**
   * Method creates empty tariff cell
   * @returns empty tariff cell in JQuery format
   */
  private createEmptyTariffCell(): JQuery {
    return $(`
      <td class='tarrif-td p-quantity p-cell tac tariff-cell'>
        <div id='tariff-cell' />
      </td>
    `);
  }

  private createEmptyTariffCellSimplified(): JQuery {
    return $(`
      <td class='tarrif-td p-quantity p-cell tac tariff-cell'>
        <div id='tariff-cell' class='simplified' />
      </td>
    `);
  }

  /**
   * Method crates empty unit price cell.
   * @returns empty unit price cell in JQuery format
   */
  private createEmptyUnitPriceCell(): JQuery {
    return $(`
      <td class='p-price p-cell tac'>
        <div style="max-height: 100%; height: 100%;" />
      </td>
    `);
  }

  /**
   * Method creates empty total price cell.
   * @returns total price cell in JQuery format
   */
  private createEmptyTotalPriceCell(): JQuery {
    return $(`
      <td class='p-total tac'>
        <div id="totalPriceDiv" style="display: flex; flex-direction: row;" />
      </td>
    `);
  }

  /**
   * Method creates empty tariff type row
   * @param productProductCode is product code of the product which tariff types belong to
   * @returns tariff type row in JQuery format
   */
  private createEmptyTariffTypeRow(productProductCode: string): JQuery {
    return $(`<tr data-owner='tuito' data-id='${productProductCode}'>`);
  }

  /**
   * Method fins row in the cart of the product by given product code
   * @param productCode is product code of the product
   * @returns product row in JQuery format
   */
  private findProductRowByProductCode(productCode: string): JQuery {
    return $(`tr[data-micro-sku='${productCode}']`);
  }

  /**
   * Method removes all tariff tables
   */
  private removeExistingTariffTables(): void {
    $("tr[data-owner='tuito']").remove();
  }

  private setTuitoModalCloseEvent() {
    $(".tuitoClose").click(() => {
      $("#tuitoModal").hide();
    });
  }

  private showTuitoPopupModal(text: string) {
    $("#tuitoModal").show();
    $("#tuitoModalContent").html(text);
  }

  /**
   * Method hides original tariff rows
   * @param tariffProductCodes are product codes of tariffs in the cart
   */
  hideTariffRowByProductCodes(tariffProductCodes: string[]): void {
    const tariffsRowSelector = tariffProductCodes.map(tpc => `tr[data-micro-sku='${tpc}']`).join(",");

    if (this.shopConfiguration.template === LayoutVariant.POP) {
      $(tariffsRowSelector, "#cart > table").hide();
    } else {
      $(tariffsRowSelector, "table.cart-table").hide();
    }
  }

  /**
   * Method checks and unchecks tariff checkboxes by given array of product codes.
   * Method is used to mimic cart behaviour (helps debounce)
   * @param tariffProductCodesInCart is array of tariff product codes that should be in the cart
   */
  mimicTariffChanges(tariffProductCodesInCart: string[]) {
    const tariffInputsInCartSelector = tariffProductCodesInCart.map(tpc => `input[tariffid='${tpc}']`).join(",");
    const tariffInputsNotInCartSelector = $("input", TARIFF_CELL_SELECTOR);

    $(tariffInputsNotInCartSelector).prop("checked", false);
    $(tariffInputsInCartSelector).prop("checked", true);
  }

  lockTariffTables(): void {
    $("input", TARIFF_CELL_SELECTOR).prop("disabled", true);
  }

  getItemsInCartCount() {
    const cartProductsSelectors =
      this.shopConfiguration.template === LayoutVariant.POP
        ? [".single-merchandise", "#cart > table"]
        : [".removeable", ".cart-table"];

    return $(cartProductsSelectors[0], cartProductsSelectors[1]).length;
  }
}
