import { LayoutVariant } from "../../Helpers/shoptet";
import type { ProductDetailPosition } from "./whitelist/whitelist";

export default class ShopConfiguration {
  simplifiedCartTableRow = false;

  get hasFourCheckoutCartColumns(): boolean {
    return this.template === LayoutVariant.POP;
  }

  get hasCheckoutModal(): boolean {
    return this.template === LayoutVariant.STEP || this.template === LayoutVariant.TANGO;
  }

  constructor(
    public readonly template: LayoutVariant,
    public readonly productDetailPosition?: ProductDetailPosition,
    public readonly originalAdvancedOrder?: boolean,
    public readonly customProductDetailInsertBeforeElement?: string
  ) {}
}
