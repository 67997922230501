import { Tariff } from "./ShoptetTariff";

export class TariffType {
  tariffs: Tariff[] = [];
  incompatibleTariffs: string[] = [];

  type: string;
  typeName: string;
  cartTitle: string;
  popup: string;
  description: string;

  constructor(
    tariffs: Tariff[],
    type: string,
    typeName: string,
    cartTitle: string,
    popup: string,
    description: string,
    incompatibleTariffs: string[]
  ) {
    tariffs.forEach(tariff => {
      this.tariffs.push(new Tariff(tariff.durationYears, tariff.productCode, tariff.premium, tariff.txtProductName));
    });

    this.incompatibleTariffs = incompatibleTariffs;

    this.type = type;
    this.typeName = typeName;
    this.cartTitle = cartTitle;
    this.popup = popup;
    this.description = description;
  }
}
