//@todo - rework (fix) function for overriding original functions instead of "adding new one"

/**
 * This function is added as handler to onClick on show related products
 * This function is added for all other templates.
 * Function counts with tuito elements that original function does not.
 * @param e is event
 */
export const onShowRelatedOtherTemplates = (e: Event) => {
  e.preventDefault();
  const $tr = $(e.target as Element)
    .closest("tr")
    .nextUntil(".related")
    .next();

  shoptet.cart.toggleRelatedProducts($tr);

  $tr.find("img").unveil();
};

/**
 * This function is added as handler to onClick on show related products
 * This function is added for Pop template.
 * Function counts with tuito elements taht original function does not.
 * @param e is event
 */
export const onShowRelatedPopTemplate = (e: Event) => {
  e.preventDefault();

  const aElement = $(e.target as Element);
  const parentRowElement = aElement.closest("tr");

  // for some reason the next() function finds two elements, the second one is the right one
  // @todo - find reason why and remove the two elements issue
  const relatedElement = $(parentRowElement.nextUntil('[class*="related-to-"]').next()[1]);
  // since this function adds another handler to onClick and does not everide the original one
  // hasClass condition has to be reversed
  if (!aElement.hasClass("open")) {
    relatedElement.find("td .related-over-wrapper").slideUp(400, () => {
      // commented code row is executed in original function
      // parentRowElement.removeClass("hide-border");
      relatedElement.css("display", "none");
    });
  } else {
    // commented code row is executed in original function
    // parentRowElement.addClass("hide-border");
    relatedElement.css("display", "table-row");
    relatedElement.find("td .related-over-wrapper").slideDown(400);
  }

  // commented code row is executed in original function
  // aElement.toggleClass("open");
};
