export enum LayoutVariant {
  /**
   * eg. https://www.nightpearl.shop/
   */
  STEP = 12,
  WALTZ = 10,
  TANGO = 9,
  // Techno = 7,
  /**
   * eg. https://www.pocitarna.cz/
   * eg. https://www.smartyhodinky.cz/
   * eg. https://www.xiaomimarket.cz/
   */
  POP = 4,

  /**
   * eg. https://www.jabkolevne.cz/
   * eg. https://www.iphonarna.sk/
   * eg. https://www.kolor.cz/
   * eg. https://www.hifimedia.cz/
   */
  CLASSIC = 11,
  TECHNO = 7,
  /**
   * eg. https://www.bestmarket.sk/
   */
  DISCO = 13,
  /**
   * eg. https://www.ipato.cz/
   */
  SOUL = 6,
  /**
   * eg. https://www.fitness-zone.cz/
   * eg. https://www.cmpsport.cz/
   */
  ROCK = 5,
}

export enum PageType {
  CART = "cart",
  PRODUCT_DETAIL = "productDetail",
  ARTICLE = "article",
}
