import type { Product } from "../../../Models/ShoptetProduct";
import { defaultErrorHandler } from "../../sharedFunctions";
import { findTariff } from "../api/findTariff";
import { Constants } from "../../../Helpers/Constants";
import WhitelistManager from "../whitelist/WhitelistManager";

export default class CurrentProduct {
  private product: Product;

  get currentProductModel(): ProductModel {
    return getShoptetDataLayer().product;
  }

  get currentProduct(): Product {
    return this.product;
  }

  async initialize() {
    const productModel = this.currentProductModel;

    const productPrice = productModel.priceWithVat ?? productModel.priceWithVatMax ?? 0;
    if (!productPrice) {
      throw new Error(`Product with product cude ${productModel.code} has no price or it's price is nullish`);
    }

    const p: Product = {
      guid: productModel.guid,
      name: productModel.name,
      productCode: productModel.code,
      value: productPrice,
      currency: productModel.currency,
      tariffTypes: [],
    };

    try {
      const [productWithTariff] = await findTariff([p]);
      this.product = productWithTariff;
    } catch (e) {
      defaultErrorHandler(e);
      throw e;
    }
  }

  canCurrentProductHaveTariffTable(): boolean {
    const currentProductModel = this.currentProductModel;
    // variants are not implemented yet so if product has variants with price range
    // then the product can't have tariff table
    if (currentProductModel.hasVariants && currentProductModel.priceWithVatMax != currentProductModel.priceWithVatMin) {
      return false;
    }

    // if product is not on whitelist then it can't have tariff table
    // @todo - replace whtielist with shopConfigartion (? @Gusta)
    if (Constants.productDiscriminator && !WhitelistManager.isProductOnWhitelist(shopId, currentProductModel.guid)) {
      return false;
    }

    // not sure what this condition do
    // @todo - find a reason for this condition and keep/update/remove it
    const price = currentProductModel.priceWithVat ? currentProductModel.priceWithVat : currentProductModel.priceWithVatMax;
    if (!price) {
      return false;
    }

    // if all tests passed then product may have tariff table
    return true;
  }
}
