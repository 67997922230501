export function debounce<T extends (...args: any[]) => ReturnType<T>>(fnc: T, time: number): (...args: Parameters<T>) => void {
  let timer: NodeJS.Timeout | undefined;
  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => fnc(...args), time);
  };
}

export function asyncDebounce<T extends (...args: any[]) => Promise<any>>(inner: T): (...args: Parameters<T>) => Promise<any> {
  let inProgressPromise: Promise<ReturnType<T>> | undefined;
  let isAnotherCallPending = false;

  return async function (...args: Parameters<T>) {
    if (inProgressPromise) {
      isAnotherCallPending = true;
      return await inProgressPromise;
    }

    let resolveFunction: (result: ReturnType<T>) => void;
    inProgressPromise = new Promise<ReturnType<T>>(resolve => (resolveFunction = resolve));

    let result = await inner(...args);

    while (isAnotherCallPending) {
      isAnotherCallPending = false;
      result = await inner(...args);
    }

    inProgressPromise = undefined;
    setTimeout(() => resolveFunction(result), 0);
    return result;
  };
}
