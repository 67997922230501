import type { ProductInCart } from "../../../Models/ShoptetProduct";
import { defaultErrorHandler } from "../../sharedFunctions";
import { findTariff } from "../api/findTariff";
import CartActionsDispatcher from "../cartActions/CartActionsDispatcher";
import { getProductsByTariffProductCode, isProductTuitoTariffByProductCode } from "../utils/utils";
import { asyncDebounce } from "../../../utils/functionUtils";

export default class Cart {
  private _previousProducts: ProductInCart[] = [];
  private _products: ProductInCart[] = [];

  // tmp cart products state is used for overwriting products during update
  // so all requests (even during update) for products from outside Cart obj. will be responded with complete data
  private previousProductsTmp: ProductInCart[] = [];
  private productsTmp: ProductInCart[] = [];

  private cartBeingUpdated = false;

  get products(): readonly ProductInCart[] {
    return this._products;
  }

  get isCartBeingUpdated(): boolean {
    return this.cartBeingUpdated;
  }

  readonly update = asyncDebounce(async (parsedItemsInCartCount?: number) => {
    this.cartBeingUpdated = true;
    await this.updateCartState(parsedItemsInCartCount);
    this.cartBeingUpdated = false;
  });

  /**
   * Method gets products that are in the cart from data layer and stores them.
   * Method also fills up products with tariffs (fetches new tariffs if needed otherwise copy them from previous state)
   * @returns updated products with GUIDs and tariffs
   */
  async updateCartState(parsedItemsInCartCount?: number): Promise<void> {
    const { cart, currency } = getShoptetDataLayer();

    // this is workaround for shoptet bug - in the case of setting quantity of above maximum
    // data layers cart is emptied.
    // This workaround checks if the cart is really empty by parsing HTML
    if (this.doesCartNeedRefresh(cart, parsedItemsInCartCount)) {
      this._products = this._previousProducts;
      this.refreshDataLayer();
      return;
    }

    this.previousProductsTmp = this._products;

    this.productsTmp = cart.map(cp => ({
      productCode: cp.code,
      name: cp.name,
      value: cp.priceWithVat,
      currency: currency,
      count: cp.quantity,
      tariffTypes: [],
      guid: this.getProductGuid(cp),
      cartItemId: cp.itemId,
      cartPriceId: cp.priceId,
    }));

    if (this.areNewTariffableProducts()) {
      try {
        const productsWithTariff = await findTariff(this.productsTmp);
        this.productsTmp = productsWithTariff;
      } catch (e) {
        defaultErrorHandler(e);
      }
    } else {
      this.copyProductTariffsFromPreviousStateToCurrent();
    }

    this.fixTariffsCartAmount();

    if (!this.areProductsSameAsLastState(this.productsTmp)) {
      this._previousProducts = this.previousProductsTmp;
    }
    this._products = this.productsTmp;
  }

  areProductsSameAsLastState(productsToCompare: ProductInCart[]) {
    return (
      this._products.length === productsToCompare.length &&
      this._products.every(p => productsToCompare.some(ptc => ptc.productCode === p.productCode && ptc.count === p.count))
    );
  }

  /**
   * Function checks if there is new product that might have tariff.
   * Any product that is new and is not tuito tariff is tariffable product.
   * @returns false if there is no new tariffable product otherwise true
   */
  areNewTariffableProducts(): boolean {
    for (const np of this.productsTmp) {
      // tariff products cannot have tariff so they are considered as untariffable
      if (isProductTuitoTariffByProductCode(np.productCode)) {
        continue;
      }

      // if new product is not found in old product that means there is new tariffable product
      const previousProduct = this.previousProductsTmp.find(p => p.productCode === np.productCode);
      if (!previousProduct) {
        return true;
      }
    }

    return false;
  }

  /**
   * @todo - this method is workaround for broken data layer updating. Remove this method once shoptet fixes the bug.
   */
  private refreshDataLayer() {
    shoptet.cartShared.removeFromCart({ itemId: "justToRefreshDataLayer" });
  }

  /**
   * @todo - this method is workaround for broken data layer updating. Remove this method once shoptet fixes the bug.
   */
  private doesCartNeedRefresh(cart: ShoptetCart, parsedItemsInCartCount?: number) {
    return !cart.length && this._previousProducts.length && parsedItemsInCartCount;
  }

  private copyProductTariffsFromPreviousStateToCurrent() {
    this.productsTmp = this.productsTmp.map(p => {
      const pp = this.previousProductsTmp.find(pp => pp.productCode === p.productCode);
      if (!pp) {
        return p;
      }

      return {
        ...p,
        tariffTypes: pp.tariffTypes,
      };
    });
  }

  private getProductGuid(product: CartItem): string {
    const productList = getShoptetProductsList();

    const productListItemWithGuidEntries = Object.entries(productList).find(([, value]) =>
      value.content_ids.includes(product.code)
    );
    // should never happen
    // if this condition pass it would signalize a bug
    if (!productListItemWithGuidEntries) {
      console.error(`Product with product code ${product.code} has not been found in product list`);
      return "";
    }

    const [, productListItemWithGuid] = productListItemWithGuidEntries;

    // if we are looking for GUID of product that is in the cart this should never pass. Otherwise GUID might be null.
    if (productListItemWithGuid.guid === null) {
      console.log(
        `Product with product code ${product.code} has no GUID set in product list. Please check if the product is in the cart`
      );
      return "";
    }

    return productListItemWithGuid.guid;
  }

  private fixTariffsCartAmount(): void {
    this.productsTmp.forEach(p => {
      if (!isProductTuitoTariffByProductCode(p.productCode)) {
        return;
      }

      const productsWithTariff = getProductsByTariffProductCode(this.productsTmp, p.productCode);
      if (!productsWithTariff.length) {
        CartActionsDispatcher.enqueueAction(() => shoptet.cartShared.removeFromCart({ itemId: p.cartItemId }));
        return;
      }
      const rightAmount = Cart.getRightAmoutTariffInCart(productsWithTariff);
      if (p.count !== rightAmount) {
        CartActionsDispatcher.enqueueAction(() =>
          shoptet.cartShared.updateQuantityInCart({ itemId: p.cartItemId, priceId: p.cartPriceId, amount: rightAmount })
        );
      }
    });
  }

  productsUpdatedRecently() {
    return this._products.filter(p =>
      this._previousProducts.every(pp => p.productCode !== pp.productCode || p.count !== pp.count)
    );
  }

  static getRightAmoutTariffInCart(productsWithTariff: ProductInCart[]): number {
    return productsWithTariff.reduce((acc, { count }) => acc + count, 0);
  }
}
