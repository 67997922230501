//#region Enums
enum ShopType {
  Shoptet = 1,
  PrestaShop = 2,
  woocomerce = 3,
}

export enum PageTypes {
  cart = "cart",
  productDetail = "productDetail",
}

//#endregion

/**
 * Typescript thinks that TUITO_VARIABLES is string but it's not.
 * Maybe find a reason why or way around. Until then this sloppy recast is needed.
 */
const tuitoVariables = process.env.TUITO_VARIABLES as unknown as {
  TEST?: boolean;
  PROD_API?: boolean;
  PRODUCT_DISCRIMINATOR?: boolean;
  SHOP_TYPE?: string;
};

export class Constants {
  //#region Images
  static infoCircle =
    "<svg width='16' height='16' fill='currentColor' class='bi bi-info-circle' viewBox='0 0 16 16'><path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/><path d='M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/></svg>";
  static tuitoLogo =
    '<svg  style="margin: 10px; min-width: 20px; max-width: 30px;" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 1101.32505 995.91513"><defs><style>.cls-1{fill:#002579;}</style></defs><polygon class="cls-1" points="0 0 0 165.434 77.804 165.434 77.804 77.794 1023.536 77.794 1023.536 165.434 1101.325 165.434 1101.325 0 0 0"/><path class="cls-1" d="M1196.63567,712.58824l-.7014.74454C1065.97658,851.57582,890.32852,927.718,701.34864,927.718c-189.33057,0-365.22465-76.39389-495.27132-215.09833l-.69617-.73406L151.26875,762.766l-.74327.7026.69617.74454c144.441,154.03565,339.812,238.86065,550.127,238.86065,209.90145,0,404.995-84.55235,549.34708-238.07417l.7014-.74454Z" transform="translate(-150.29877 -7.15862)"/></svg>';
  //#endregion

  //#region Settings
  static test: boolean = tuitoVariables.TEST !== undefined ? tuitoVariables.TEST : true;
  static prodApi: boolean = tuitoVariables.PROD_API !== undefined ? tuitoVariables.PROD_API : true;
  static productDiscriminator = tuitoVariables.PRODUCT_DISCRIMINATOR !== undefined ? tuitoVariables.PRODUCT_DISCRIMINATOR : true;
  static shopType: ShopType = tuitoVariables.SHOP_TYPE
    ? ShopType[tuitoVariables.SHOP_TYPE as keyof typeof ShopType]
    : ShopType.Shoptet;
  //#endregion

  //#region DOM events
  static CartDOMLoaded = "ShoptetDOMCartContentLoaded";
  static cart = "cart";
  static productDetail = "productDetail";
  static product = "product";
  //#endregion
  //#region URLs
  static API_URL = `https://api${this.test && !this.prodApi ? ".test" : ""}.tuito.cz/`;
  static INSURANCES_URL =
    this.API_URL +
    (this.shopType == ShopType.Shoptet ? "shoptet" : this.shopType == ShopType.PrestaShop ? "prestashop" : "") +
    "/find-tariff";
  static SHOPTET_CDN = "https://cdn.myshoptet.com/usr/tuito.myshoptet.com/user/documents/upload/addon/";
  static CUSTOM_SHOPTET_JS: string;
  static CUSTOM_SHOPTET_CSS: string;
  static PRESTASHOP_TUITO_API_CONTROLLER = "/tuitoinsurance/insurance";
  static readonly PRESTASHOP_TUITO_API_CONTROLLER_KERNEL = "/index.php?fc=module&module=tuitoinsurance&controller=insurance";
  static removedFromDetail = "removedFromDetail";
  //#endregion

  static getShoptetCustomCss(shopId: string) {
    return `${this.SHOPTET_CDN}${this.test && !this.prodApi ? "test" : "prod"}/customs/tuito-${shopId}.css`;
  }

  static WPTEST: boolean;
}
