import type { ShopCustomization } from "../Models/shopCustomization";

const customizations: Record<string, ShopCustomization> = {
  "299914": {
    hasCustomCss: true,
  },
  "64530": {
    hasCustomCss: true,
    hasSimplifiedCartTableRow: true,
  },
  // novotny-elektro.cz
  "349616": {
    tariffsOnCartDialog: false
  }
};
export { customizations };
