interface DefaultError extends Error {
  [key: string]: unknown;
}

/**
 * Function handles any error
 * @param error is any error thrown in shop client
 * @todo - separate error handler from this file and make proper error handling
 */
export function defaultErrorHandler(error: unknown): void {
  if (typeof error === "string") {
    console.log(error);
    return;
  }

  if (typeof error === "object") {
    const castedError = error as DefaultError;

    if (castedError.message) {
      console.log(castedError.message);
    } else {
      console.log(castedError);
    }
  }
}

export function getCurrencySymbol(currencyCode: string): string {
  switch (currencyCode) {
    case "CZK":
      return "K\u010D";
    case "EUR":
      return "\u20ac";
    default:
      return currencyCode;
  }
}

export function getLocalizedLengthString(length: number): string {
  if (length === 1) {
    return "1 rok";
  } else if (length > 1 && length < 5) {
    return `${length} roky`;
  } else {
    return `${length} let`;
  }
}
