import { Constants } from "../Helpers/Constants";
import { waitForDocumentLoaded } from "../Helpers/document";
import { LayoutVariant, PageType } from "../Helpers/shoptet";
import type { ShopCustomization } from "../Models/shopCustomization";
import { defaultErrorHandler } from "./sharedFunctions";
import Cart from "./shoptet/products/Cart";
import CheckoutController from "./shoptet/checkout/CheckoutController";
import ProductDetailController from "./shoptet/productDetail/ProductDetailController";
import CartActionsDispatcher from "./shoptet/cartActions/CartActionsDispatcher";
import { onShowRelatedOtherTemplates, onShowRelatedPopTemplate } from "./shoptet/utils/showRelatedUtils";
import { customizations } from "./shoptetCustomizations";
import ShopConfiguration from "./shoptet/ShopConfiguration";
import CurrentProduct from "./shoptet/products/CurrentProduct";
import WhitelistManager from "./shoptet/whitelist/WhitelistManager";

export default class ShoptetClient {
  private cart = new Cart();
  private shopConfiguration: ShopConfiguration;
  private currentProduct = new CurrentProduct();
  private checkoutController?: CheckoutController;
  private productDetailController?: ProductDetailController;

  get layoutVariant(): LayoutVariant {
    const layoutVariantId = parseInt(shoptet.abilities.about.id);

    switch (layoutVariantId) {
      case LayoutVariant.POP:
        return LayoutVariant.POP;
      case LayoutVariant.STEP:
        return LayoutVariant.STEP;
      case LayoutVariant.TANGO:
        return LayoutVariant.TANGO;
      default:
        return LayoutVariant.CLASSIC;
    }
  }

  get pageType() {
    return getShoptetDataLayer().pageType;
  }

  async initialize() {
    await waitForDocumentLoaded();
    $(document).on("ShoptetCartUpdated", () => this.dispatchNextCartAction().catch(defaultErrorHandler));

    this.shopConfiguration = new ShopConfiguration(
      this.layoutVariant,
      WhitelistManager.getProductDetailPosition(shopId),
      shoptet.config.showAdvancedOrder,
      shopId === "481776" ? "p-final-price-wrapper" : undefined
    );
    this.injectShopCustomizations(shopId); // shopId is a global variable provided by Shoptet

    await this.updateCart();

    if (this.pageType === PageType.CART) {
      this.initCheckout();
      return;
    } else if (this.pageType === PageType.PRODUCT_DETAIL) {
      try {
        await this.initProductDetail();
      } catch (e) {
        defaultErrorHandler(e);
      }
    }

    if (this.shopConfiguration.hasCheckoutModal) {
      this.initCheckoutModal();
    }
  }

  private initCheckout() {
    this.checkoutController = new CheckoutController(this.cart, this.shopConfiguration);
    this.checkoutController.update();

    $(document).on("ShoptetDOMCartContentLoaded", () => this.onShoptetDomCartContentLoaded().catch(defaultErrorHandler));
    $(document).on("ShoptetDOMAdvancedOrderLoaded", () => this.onShoptetDOMAdvancedOrderLoaded().catch(defaultErrorHandler));

    // Function handles onShowRelated click event. Handling this event is used to properly show related products (tuito tariff tables kinda breaks the original functions)
    $(document).on("click", "a.show-related", e => {
      if (this.layoutVariant === LayoutVariant.POP) {
        onShowRelatedPopTemplate(e);
      } else {
        onShowRelatedOtherTemplates(e);
      }
    });
  }

  private initCheckoutModal() {
    this.checkoutController = new CheckoutController(this.cart, this.shopConfiguration);
    this.checkoutController.update();

    $(document).on("ShoptetDOMCartContentLoaded", () => this.onShoptetDomCartContentLoaded().catch(defaultErrorHandler));
    $(document).on("click", "a.show-related", e => onShowRelatedOtherTemplates(e));
  }

  private async initProductDetail() {
    if (!this.currentProduct.canCurrentProductHaveTariffTable()) {
      return;
    }

    await this.currentProduct.initialize();

    this.productDetailController = new ProductDetailController(this.currentProduct, this.cart, this.shopConfiguration);
    this.productDetailController.update();

    $(document).on("ShoptetCartAddCartItem", () => this.enqueueAndTriggerProductDetailCartActions());
    // complements ShoptetCartUpdated event
    // ideally we should have no handler for this but ShoptetCartUpdated events are buggy and are not always triggered
    $(document).on("ShoptetDataLayerUpdated", () => this.dispatchNextCartAction().catch(defaultErrorHandler));
    $(document).on("ShoptetDOMAdvancedOrderLoaded", () => this.dispatchNextCartAction().catch(defaultErrorHandler));
  }

  private async dispatchNextCartAction() {
    await this.updateCart();
    CartActionsDispatcher.dispatchNextAction();
  }

  private updateControllers() {
    this.checkoutController?.update();
    this.productDetailController?.update();
  }

  private async updateCart() {
    this.updateControllers();
    await this.cart.update(this.checkoutController?.getItemsInCartCount());
    this.updateControllers();
  }

  // @todo - think about other solution
  private async enqueueAndTriggerProductDetailCartActions() {
    await this.updateCart();

    const updatedProducts = this.cart.productsUpdatedRecently();
    if (updatedProducts.some(up => up.productCode === this.currentProduct.currentProduct.productCode)) {
      this.productDetailController?.enqueueAndTriggerCartUpdateActions();
    }
  }

  /**
   * Function handles ShoptetDomCartContentLoaded event.
   * ShoptetDomCartContentLoaded is triggered when DOM CART is loaded.
   * When DOM CART is loaded data layer is refreshed.
   * Handling this event is used to insert tariff tables.
   */
  private async onShoptetDomCartContentLoaded() {
    await this.updateCart();
    // this is workaround because this event is sometimes not triggered when it should. Remove this when shoptet fixes the bug
    shoptet.scripts.signalCustomEvent("ShoptetCartUpdated");
  }

  /**
   * Function handles ShoptetDomAdvancedOrderLoaded event.
   * ShoptetDomAdvancedOrderLoaded is triggered when advancedOrder modal is shown.
   * In our case advnacedOrder modal is shown when something is added to the cart - data layer is refreshed.
   * Handling this event is used to close advanced order modal on cart page when tariff is added to the cart.
   * Handling this event is used just to cover unreliabilty of buggy silent workaround
   */
  private async onShoptetDOMAdvancedOrderLoaded() {
    await this.updateCart();
    if (!this.cart.areNewTariffableProducts()) {
      shoptet.modal.close();
    }
  }

  private injectShopCustomizations(shopId: string) {
    /**
     * @todo - remove once cache is fixed
     */
    const tmpShopId = shopId === "64530" ? "64530_37" : shopId;

    const { hasCustomCss, hasSimplifiedCartTableRow } = Object.assign(
      {
        // put default values here
      } as ShopCustomization,
      customizations[shopId]
    );

    if (hasCustomCss) {
      const url = Constants.getShoptetCustomCss(tmpShopId);
      $("head").append(`<link rel='stylesheet' type='text/css' href='${url}' />`);
    }
    if (hasSimplifiedCartTableRow !== undefined) {
      this.shopConfiguration.simplifiedCartTableRow = hasSimplifiedCartTableRow;
    }
  }
}
