import { Constants } from "../../../Helpers/Constants";
import type { Product } from "../../../Models/ShoptetProduct";
import type { Tariff } from "../../../Models/ShoptetTariff";
import type { TariffType } from "../../../Models/ShoptetTariffType";
import { getCurrencySymbol, getLocalizedLengthString } from "../../sharedFunctions";
import type ShopConfiguration from "../ShopConfiguration";
import { ProductDetailPosition } from "../whitelist/whitelist";

const TUITO_TABLE_IDENTIFICATOR = "table.tuito-data-table";

export default class ProductDetailViewer {
  private advancedOrderModalHeightModified = false;

  private advancedOrderModalProductNameText: string;

  constructor(
    private readonly shopConfiguration: ShopConfiguration,
    private onCheckboxClickHandler: (isChecked: boolean, clickedTariff: Tariff) => void,
    private onCheckboxClickHandlerAdvancedOrder: (isChecked: boolean, clickedTariff: Tariff) => void,
    private customSelector?: string
  ) {
    this.setTuitoModalCloseEvent();
  }

  buildAndInsertTable(product: Product, tariffProductCodesInCart: string[]): void {
    this.removExistingTariffTable();

    if (this.shopConfiguration.originalAdvancedOrder && !this.checkIfAdvancedOrderModalBelongsToCurrentProduct()) {
      return;
    }

    const { guid, productCode, tariffTypes, currency } = product;

    const tableAdvanceOrder = this.buildTable(guid, productCode, tariffTypes, currency, tariffProductCodesInCart, (...args) =>
      this.onCheckboxClickHandlerAdvancedOrder(...args)
    );
    const table = this.buildTable(guid, productCode, tariffTypes, currency, tariffProductCodesInCart, (...args) =>
      this.onCheckboxClickHandler(...args)
    );

    this.insertTableAdvancedOrderByConfiguration(tableAdvanceOrder);
    this.insertTableByConfiguration(table);
  }

  lockTariffTable(): void {
    $("input", TUITO_TABLE_IDENTIFICATOR).prop("disabled", true);
  }

  mimicTariffChanges(tariffProductCodesInCart: string[]): void {
    const tariffInputsInCartSelector = tariffProductCodesInCart.map(tpc => `input[tariffid='${tpc}']`).join(",");
    const tariffInputsNotInCartSelector = $("input", TUITO_TABLE_IDENTIFICATOR);

    $(tariffInputsNotInCartSelector).prop("checked", false);
    $(tariffInputsInCartSelector).prop("checked", true);
  }

  private checkIfAdvancedOrderModalBelongsToCurrentProduct() {
    // should be true on the first modal open
    if (!this.advancedOrderModalProductNameText) {
      this.setAdvancedOrderModalProductNameText();
      return true;
    }

    return this.getProductNameFromAdvancedOrderModal() === this.advancedOrderModalProductNameText;
  }

  private getProductNameFromAdvancedOrderModal() {
    return $("[data-testid=popupProductItemName]", "#colorbox").text().trim();
  }

  private setAdvancedOrderModalProductNameText() {
    const text = $("[data-testid=popupProductItemName]", "#colorbox").text().trim();

    if (text) {
      this.advancedOrderModalProductNameText = text;
    }
  }

  private removExistingTariffTable(): void {
    $(TUITO_TABLE_IDENTIFICATOR).remove();
  }

  private insertTableAdvancedOrderByConfiguration(table: JQuery) {
    const { originalAdvancedOrder, productDetailPosition: position } = this.shopConfiguration;

    if (
      position === ProductDetailPosition.ADVANCED_ORDER ||
      position === ProductDetailPosition.ALL ||
      (!position && originalAdvancedOrder)
    ) {
      this.insertTableAdvancedOrder(table);
    }
  }

  private insertTableByConfiguration(table: JQuery) {
    const { originalAdvancedOrder, productDetailPosition: position } = this.shopConfiguration;

    if (
      position === ProductDetailPosition.PRODUCT_DETAIL ||
      position === ProductDetailPosition.ALL ||
      (!position && !originalAdvancedOrder)
    ) {
      this.insertTableProductDetailPage(table);
    }
  }

  private buildTable(
    productGuid: string,
    productProductCode: string,
    tariffTypes: TariffType[],
    currencyIsoCode: string,
    tariffProductCodesInCart: string[],
    checkboxOnClickHandler: (isChecked: boolean, clickedTariff: Tariff) => void
  ): JQuery {
    const table = this.createEmptyTable();

    tariffTypes.forEach(tt => {
      const newRow = this.createEmptyTariffTypeRow(productProductCode);

      const descriptionCell = this.createDescriptionCell(tt.cartTitle, tt.typeName, tt.description);

      const tariffsCell = this.createEmptyTariffCell();

      const { tariffs } = tt;
      tariffs.forEach((t, index) => {
        if (Object.keys(t).length === 0) {
          return;
        }

        const lengthString = getLocalizedLengthString(t.durationYears);
        const variantString = t.premium.toLocaleString("cs-CZ");
        const currencyString = getCurrencySymbol(currencyIsoCode);

        $("div[type='tariffCell']", tariffsCell).append(
          this.createBodyTariffCell(
            productGuid,
            productProductCode,
            t,
            lengthString,
            tariffProductCodesInCart,
            variantString,
            currencyString,
            checkboxOnClickHandler
          )
        );
      });

      descriptionCell.appendTo(newRow);
      tariffsCell.appendTo(newRow);

      $("svg", newRow).click(() => {
        if (tt.popup) {
          $("#tuitoModal").show();
          $("#tuitoModalContent").html(tt.popup);
        }
      });

      table.prepend(newRow);
    });

    return table;
  }

  private createEmptyTable(): JQuery {
    return $(`
            <table class='tuito-data-table' style="margin-bottom: 60px;">
                <tbody />
            </table>
        `);
  }

  private createEmptyTariffTypeRow(productProductCode: string): JQuery {
    return $(`<tr data-owner='tuito' data-id='${productProductCode}'>`);
  }

  private createDescriptionCell(rowTitle: string, infoName: string, description: string): JQuery {
    return $(`
            <td class='p-name'>
                ${rowTitle} &nbsp;
                <span title='${infoName}'>${Constants.infoCircle}</span>
                <br />
                <span class='p-description-product-detail'>${description}</span>
            </td>
        `);
  }

  private createEmptyTariffCell(): JQuery {
    return $(`
            <td class='tarrif-td p-quantity p-cell tac'>
                <div type='tariffCell'/>
            </td>
        `);
  }

  private createBodyTariffCell(
    productGuid: string,
    productProductCode: string,
    tariff: Tariff,
    lengthString: string,
    tariffProductCodesInCart: string[],
    variantString: string,
    currencyString: string,
    checkboxOnClickHandler: (isChecked: boolean, clickedTariff: Tariff) => void
  ): JQuery {
    const bodyTariffCell = $(`
      <div class='tariff-cell-simplified'>
        <input type='checkbox' id='${productProductCode}_${tariff.productCode}' productId='${productGuid}' tariffId='${tariff.productCode}' data-owner='tuito' />
        <label for='${productProductCode}_${tariff.productCode}'>
            ${lengthString} za <b> ${variantString} ${currencyString} </b>
        </label>
      </div>
    `);

    $("input", bodyTariffCell).click(() => checkboxOnClickHandler($("input", bodyTariffCell).prop("checked"), tariff));
    $("input", bodyTariffCell).prop("checked", tariffProductCodesInCart.includes(tariff.productCode));

    return bodyTariffCell;
  }

  private setTuitoModalCloseEvent() {
    $(".tuitoClose").click(() => {
      $("#tuitoModal").hide();
    });
  }

  private fixHeightAdvancedOrderModal(tableHeight: number): void {
    this.advancedOrderModalHeightModified = true;

    const colorbox = $("div#colorbox");
    colorbox.height(colorbox.height() + tableHeight);

    const cboxWrapper = $("div#cboxWrapper");
    cboxWrapper.height(cboxWrapper.height() + tableHeight);

    const cboxContent = $("div#cboxContent");
    cboxContent.height(cboxContent.height() + tableHeight);

    const cboxLoadedContent = $("div#cboxLoadedContent");
    cboxLoadedContent.height(cboxLoadedContent.height() + tableHeight);
  }

  private insertTableAdvancedOrder(table: JQuery) {
    $("[data-testid='popupProductItemName']", "#colorbox").after(table);

    if (!this.advancedOrderModalHeightModified) {
      this.fixHeightAdvancedOrderModal(table.height());
    }
  }

  private insertTableProductDetailPageCustomSelector(table: JQuery): void {
    // should not pass - condition for typescript
    if (!this.customSelector) {
      return;
    }

    const customElement = $(this.customSelector);
    if (!customElement.length) {
      console.log("No element has been found by given custom selector in 'insertTableProductDetailPageCustomSelector' scope");

      return;
    }

    table.insertAfter(customElement);
  }

  private insertTableProductDetailPage(table: JQuery): void {
    if (this.customSelector) {
      this.insertTableProductDetailPageCustomSelector(table);

      return;
    }

    (this.shopConfiguration.customProductDetailInsertBeforeElement
      ? $(`div.${this.shopConfiguration.customProductDetailInsertBeforeElement}`)
      : $("div.add-to-card")
    ).before(table);
  }
}
